import React from "react";

import { AboutHome } from "../components/about";
import Layout from "../components/layout";
import Seo from "../components/seo";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/about.png",
};

const canonicalUrl = "https://hasura.io/about/";

const About = props => (
  <Layout location={props.location}>
    <Seo
      title="Hasura makes data access easy, fast, secure & scalable"
      description="Hasura is designed to save developers time in building, maintaining, and serving a production-ready enterprise-grade GraphQL API."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <AboutHome />
  </Layout>
);

export default About;
