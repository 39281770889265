import React from "react";

import { StyledAbout } from "./styles/StyledAbout";
import { LandingBanner } from "./LandingBanner";
import { Mission } from "./Misson";
import { TeamSlider } from "../careers/TeamSlider";
import { HasuraInvestors } from "./HasuraInvestors";
import { BrandIntegration } from "./BrandIntegration";

export const AboutHome = () => (
  <StyledAbout>
    <LandingBanner />
    <Mission />
    <TeamSlider />
    <HasuraInvestors />
    <BrandIntegration />
  </StyledAbout>
);
