import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { StyledTeamSlider } from "./StyledCareersPage";
import imgOne from "./images/image167.jpg";
import imgTwo from "./images/image162.jpg";
import imgThree from "./images/image157.jpg";
import imgFour from "./images/image154.jpg";
import imgFive from "./images/image153.jpg";
import imgSix from "./images/image163.jpg";
import imgSeven from "./images/image166.jpg";
import imgEight from "./images/image164.jpg";
import imgNine from "./images/image149.jpg";
import imgTen from "./images/image150.jpg";
import imgEleven from "./images/image151.jpg";
import imgTwelve from "./images/image165.jpg";
import imgThirteen from "./images/image13.jpg";
// import imgFourteen from "./images/image14.jpg";
import imgFifteen from "./images/image15.jpg";
import imgSixteen from "./images/image16.jpg";

const teamSlidesArrOne = [
  {
    imgSrc: imgOne,
    altText: "team_image_01",
  },
  {
    imgSrc: imgTwo,
    altText: "team_image_02",
  },
  {
    imgSrc: imgThree,
    altText: "team_image_03",
  },
  {
    imgSrc: imgFour,
    altText: "team_image_04",
  },
  {
    imgSrc: imgFive,
    altText: "team_image_05",
  },
  {
    imgSrc: imgSix,
    altText: "team_image_06",
  },
  {
    imgSrc: imgSeven,
    altText: "team_image_07",
  },
  {
    imgSrc: imgFifteen,
    altText: "team_image_15",
  },
];

const teamSlidesArrTwo = [
  {
    imgSrc: imgEight,
    altText: "team_image_08",
  },
  {
    imgSrc: imgNine,
    altText: "team_image_09",
  },
  {
    imgSrc: imgTen,
    altText: "team_image_10",
  },
  {
    imgSrc: imgEleven,
    altText: "team_image_11",
  },
  {
    imgSrc: imgTwelve,
    altText: "team_image_12",
  },
  {
    imgSrc: imgThirteen,
    altText: "team_image_13",
  },
  {
    imgSrc: imgSixteen,
    altText: "team_image_16",
  },
];

const slideOneSettings = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: -1,
  autoplay: true,
  speed: 16000,
  autoplaySpeed: 100,
  pauseOnHover: true,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: -1,
      },
    },
  ],
};

const slideTwoSettings = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 16000,
  autoplaySpeed: 100,
  pauseOnHover: true,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

export const TeamSlider = () => (
  <StyledTeamSlider>
    <Slider className="sliderCarousel" {...slideOneSettings}>
      {teamSlidesArrOne.map(({ imgSrc, altText }, index) => (
        <div key={index} className="team_img_div">
          <img src={imgSrc} alt={altText} />
        </div>
      ))}
    </Slider>
    <Slider className="sliderCarousel" {...slideTwoSettings}>
      {teamSlidesArrTwo.map(({ imgSrc, altText }, index) => (
        <div key={index} className="team_img_div">
          <img src={imgSrc} alt={altText} />
        </div>
      ))}
    </Slider>
  </StyledTeamSlider>
);
