export default Object.freeze({
  display_1: {
    fontWeight: "500",
    fontSize: "80px",
    lineHeight: "110%",
    fontFamily: "IBM Plex Sans",
  },
  display_2: {
    fontWeight: "bold",
    fontSize: "56px",
    lineHeight: "110%",
    fontFamily: "IBM Plex Sans",
    letterSpacing: "-0.02em",
  },
  display_2_xs: {
    fontSize: "36px",
    lineHeight: "122%",
    fontFamily: "IBM Plex Sans",
  },
  display_3: {
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "116%",
    fontFamily: "IBM Plex Sans",
  },
  title_new_1: {
    fontWeight: "500",
    fontSize: "40px",
    lineHeight: "116%",
    fontFamily: "IBM Plex Sans",
  },
  title_new_2: {
    fontWeight: "500",
    fontSize: "32px",
    lineHeight: "124%",
    fontFamily: "IBM Plex Sans",
  },
  title_new_3: {
    fontWeight: "500",
    fontSize: "28px",
    lineHeight: "128%",
    fontFamily: "IBM Plex Sans",
  },
  title_new_4: {
    fontSize: "24px",
    lineHeight: "132%",
    fontFamily: "IBM Plex Sans",
  },
  quote_1: {
    fontWeight: "300",
    fontSize: "36px",
    lineHeight: "122%",
    fontFamily: "IBM Plex Sans",
  },
  quote_2: {
    fontWeight: "300",
    fontSize: "28px",
    lineHeight: "128%",
    fontFamily: "IBM Plex Sans",
  },
  lead: {
    fontWeight: "400",
    fontSize: "28px",
    lineHeight: "128%",
    fontFamily: "IBM Plex Sans",
  },
  label_1: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "100%",
    fontFamily: "IBM Plex Sans",
  },
  body_1: {
    fontSize: "20px",
    lineHeight: "150%",
    fontFamily: "IBM Plex Sans",
  },
  body_2: {
    fontSize: "18px",
    lineHeight: "155%",
    fontFamily: "IBM Plex Sans",
  },
  body_3: {
    fontSize: "16px",
    lineHeight: "160%",
    fontFamily: "IBM Plex Sans",
  },
  title_1: {
    fontSize: "96px",
    lineHeight: "90%",
    fontWeight: "bold",
    fontFamily: "Darker Grotesque",
  },
  title_1b: {
    fontSize: "84px",
    lineHeight: "114%",
    fontWeight: "bold",
    fontFamily: "IBM Plex Sans",
  },
  title_2: {
    fontSize: "72px",
    lineHeight: "90%",
    fontWeight: "bold",
    fontFamily: "Darker Grotesque",
  },
  title_2b: {
    fontSize: "72px",
    lineHeight: "110%",
    fontFamily: "IBM Plex Sans",
  },
  title_3: {
    fontSize: "56px",
    lineHeight: "100%",
    fontFamily: "Darker Grotesque",
  },
  title_3b: {
    fontSize: "56px",
    lineHeight: "124%",
    fontWeight: "600",
    fontFamily: "IBM Plex Sans",
  },
  title_4: {
    fontSize: "40px",
    lineHeight: "136%",
    fontWeight: "500",
    fontFamily: "IBM Plex Sans",
  },
  subtitle_1: {
    fontSize: "32px",
    lineHeight: "144%",
    fontWeight: "500",
    fontFamily: "IBM Plex Sans",
  },
  subtitle_1_light: {
    fontSize: "32px",
    lineHeight: "144%",
    fontWeight: "300",
    fontFamily: "IBM Plex Sans",
  },
  subtitle_2: {
    fontSize: "24px",
    lineHeight: "156%",
    fontWeight: "500",
    fontFamily: "IBM Plex Sans",
  },
  description_1: {
    fontSize: "18px",
    lineHeight: "160%",
    fontFamily: "IBM Plex Sans",
  },
  description_code_18: {
    fontSize: "18px",
    lineHeight: "148%",
    fontFamily: "IBM Plex Mono",
  },
  description_code_14: {
    fontSize: "14px",
    lineHeight: "180%",
    fontFamily: "IBM Plex Mono",
  },
  description_2: {
    fontSize: "16px",
    lineHeight: "160%",
    fontFamily: "IBM Plex Sans",
  },
  description_3: {
    fontSize: "14px",
    lineHeight: "160%",
    fontFamily: "IBM Plex Sans",
  },
  description_tag: {
    fontFamily: "IBM Plex Sans",
    fontSize: "12px",
    lineHeight: "180%",
  },
  description_2_mono: {
    fontSize: "16px !important",
    lineHeight: "160% !important",
    fontWeight: "normal !important",
    fontFamily: "IBM Plex Mono !important",
  },
  navigation: {
    fontSize: "14px",
    lineHeight: "150%",
  },
  table_header_2: {
    fontSize: "20px",
    lineHeight: "120%",
  },
  footer_title: {
    fontSize: "12px",
    lineHeight: "100%",
    fontWeight: "600",
    fontFamily: "IBM Plex Sans !important",
  },
  btn_small: {
    fontSize: "12px",
    lineHeight: "100%",
    fontWeight: "600",
    fontFamily: "IBM Plex Sans",
  },
});
