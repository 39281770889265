import React from "react";
import { Link } from "gatsby";

import { StyledBrandIntegration } from "./styles/StyledAbout";
import { Image } from "../../globals/UIKit";
import { Icon } from "../../globals/icons";
import { scrollToTop } from "../../utils/helpers";
import hasuraLogo from "../learn/images/hasura-blue.svg";

const otherLinksArr = [
  // {
  //   icon: "image",
  //   title: "Brand Assets & Media kit",
  //   desc: "Official Logos, brand guidelines, photos & other resources",
  //   linkURL: `/brand/`,
  // },
  {
    icon: "community_call",
    title: "User Stories",
    desc: "Find out what our users have to say about Hasura",
    linkURL: `/case-studies/`,
  },
  {
    icon: "careers",
    title: "Careers",
    desc: "Join our globally distributed team and work on the future of GraphQL",
    linkURL: `/careers/`,
  },
];

export const BrandIntegration = () => (
  <StyledBrandIntegration>
    <div className="containerWrapper">
      <div className="content-wrapper">
        <Image src={hasuraLogo} alt="Hasura" width="198px" />
        <h2>Our Name and Logo</h2>
        <p>
          The name Hasura comes from a portmanteau of Asura, the Sanskrit word for demon, and
          Haskell. Asura refers to daemons, or computer programs that run as background processes.
          Haskell is the functional programming language we used to build Hasura in, and is Hasura’s
          one true ❤️
        </p>
      </div>
      <div className="other-links-div twogridColumns">
        {otherLinksArr.map(({ icon, title, desc, linkURL }) => (
          <Link to={linkURL} key={title} onClick={scrollToTop}>
            <div className="link-div">
              <Icon variant={icon} size="lg" minWidth="40px" minHeight="40px" />
              <h3>{title}</h3>
              <p>{desc}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  </StyledBrandIntegration>
);
