import React from "react";

import { StyledInvestors } from "./styles/StyledAbout";
import { Image } from "../../globals/UIKit";
import nexusLogo from "./images/nexus-logo.png";
import vertexLogo from "./images/vertex.png";
import striveLogo from "./images/strive.svg";
import lightSpeedLogo from "./images/lightspeed-logo.png";
import greenoakLogo from "./images/greenoak.png";

const investorsArr = [
  {
    investor: "Greenoaks",
    logoImg: greenoakLogo,
    refURL: `https://www.greenoaks.com/`,
  },
  {
    investor: "Vertex",
    logoImg: vertexLogo,
    refURL: `https://www.vertexventures.com/`,
  },
  {
    investor: "Lightspeed Ventures",
    logoImg: lightSpeedLogo,
    refURL: `https://lsvp.com/`,
  },
  {
    investor: "Nexus",
    logoImg: nexusLogo,
    refURL: `https://nexusvp.com/`,
  },
  {
    investor: "Strive",
    logoImg: striveLogo,
    refURL: `https://strive.vc/en/`,
  },
];

export const HasuraInvestors = () => (
  <StyledInvestors>
    <div className="containerWrapper">
      <h2>Our Investors</h2>
      <div className="investors-wrapper">
        {investorsArr.map(({ investor, logoImg, refURL }) => (
          <a key={investor} href={refURL} target="_blank" rel="noopener noreferrer">
            <div className="investor-div">
              <Image src={logoImg} alt={investor} />
            </div>
          </a>
        ))}
      </div>
    </div>
  </StyledInvestors>
);
