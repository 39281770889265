import React from "react";

import { StyledMission } from "./styles/StyledAbout";

export const Mission = () => (
  <StyledMission>
    <div className="containerWrapper">
      <div className="about-content-wrapper">
        <h2>Our mission</h2>
        <div>
          <p>
            With the rise of cloud-native and rich frontend frameworks, we can now build, ship and
            iterate on applications extremely fast. However, data and event delivery have emerged as
            the key bottleneck where teams spend months architecting and building just to enable
            their developers to get on with the business of building things!
          </p>
          <p>
            Hasura makes data access easy,{" "}
            <b>by instantly composing a GraphQL API that is backed by databases and services</b> so
            that the developer team (or API consumers) get immediately productive. The nature of
            GraphQL itself and Hasura’s dynamic approach makes integration and iteration easy.
          </p>
          <p>
            Hasura’s event engine makes it possible to embrace cloud-native without having to worry
            about non-functional things scaling, reliability and delivery guarantees. Developers can
            easily build business logic that publishes and consumes events, that reacts to changes
            in data or gets scheduled based on time.
          </p>
        </div>
      </div>
      <div className="about-content-wrapper">
        <h2>About us</h2>
        <div>
          <p>
            Hasura was open-sourced in July 2018 and since then tens of thousands of developers have
            used Hasura to build their data layer for modern applications. Hasura has also seen
            adoption across Fortune 500 corporations, fast growing startups &amp; product
            development agencies. We have highlighted some of these users on our case studies page
          </p>
          <p>We are a globally distributed team, with offices in San Francisco &amp; Bangalore.</p>
        </div>
      </div>
    </div>
  </StyledMission>
);
