import styled from "styled-components";

import globeIllus from "../images/bg_circle.svg";
import { mq } from "../../../globals/utils";
export const StyledAbout = styled.div`
  font-family: "IBM Plex Sans";

  p,
  h1,
  h2,
  h3 {
    margin: 0;
    color: #1b2738;
  }

  h2 {
    font-size: 32px;
    font-weight: 500;
    line-height: 46px;
  }
`;

export const StyledLandingBanner = styled.section`
  background: linear-gradient(180deg, #f4fbff 7.16%, rgba(255, 255, 255, 0) 100%);
  padding: 136px 0;

  text-align: center;

  h1 {
    font-size: 56px;
    font-weight: 600;
    line-height: 69px;
    margin-bottom: 24px;
  }

  p {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
  }

  @media (max-width: 760px) {
    padding: 80px 0;
  }

  @media (max-width: 650px) {
    h1 {
      font-size: 26px;
      font-weight: 500;
      line-height: 39px;
      margin-bottom: 12px;
    }

    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
    }
  }
`;

export const StyledMission = styled.section`
  margin-bottom: 64px;

  .about-content-wrapper {
    display: flex;
    align-items: flex-start;
    padding-top: 42px;

    h2 {
      min-width: 289px;
      white-space: nowrap;
    }

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      margin-bottom: 24px;
    }
  }

  @media (max-width: 1100px) {
    .about-content-wrapper {
      padding-left: 62px;
      padding-right: 62px;
    }
  }

  @media (max-width: 760px) {
    .about-content-wrapper {
      flex-direction: column;
      padding: 0;

      h2 {
        margin-bottom: 16px;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
      }
    }
  }
`;

export const StyledInvestors = styled.section`
  h2 {
    text-align: center;
    margin-bottom: 8px;
  }

  .investors-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .investor-div {
    background: #fff;
    margin-top: 40px;
    margin: 40px 18px 0;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    width: 300px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      box-shadow: 0 4px 20px 0 rgb(0 0 0 / 16%);
    }

    img {
      max-width: 155px;
    }
  }
`;

export const StyledBrandIntegration = styled.section`
  margin-top: 120px;
  padding-top: 10.5%;
  background-image: url(${globeIllus});
  background-size: cover;
  background-repeat: no-repeat;
  
  .content-wrapper {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    margin-top: 35px;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    padding: 16px 0;
    width: 60%;
  }

  .other-links-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 52px;
    padding-top: 60px;
    padding-bottom: 20px;
  }
  .twogridColumns {
    grid-template-columns: 1fr 1fr;
    width: 70%;
    margin: 0 auto;
  }
  .link-div {
    margin-top: 40px;
    height: 202px;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 32px 10px;
    text-align: center;
    width: 100%;

    h3 {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      margin-top: 16px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      width: 100%;
      padding-top: 6px;
    }

    &:hover {
      box-shadow: 0 14px 60px 0 rgb(0 0 0 / 16%);
    }
  }

  @media (max-width: 950px) {
    .other-links-div {
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
    }
    .link-div {
      margin-top: 10px;
    }
  }

  @media (max-width: 790px) {
    margin-top: 80px;
    padding-top: 0;
    background-image: none;
    .other-links-div {
      grid-template-columns: 1fr;
    }

    h2 {
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
    }

    p {
      width: 100%;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
    }
  }
  ${mq.between("md", "lg")} {
    .twogridColumns {
      width: 100%;
    }
  }
  ${mq.below.md} {
    .twogridColumns {
      grid-template-columns: 1fr;
      width: 100%;
    }
  }
`;
