export default Object.freeze({
  white: "hsla(0, 0%, 100%, 1)",
  black: "hsla(0, 0%, 0%, 1)",
  green_0: "hsla(155, 100%, 98%, 1)",
  green_10: "hsla(162, 100%, 93%, 1)",
  green_20: "hsla(155, 71%, 83%, 1)",
  green_30: "hsla(155, 58%, 66%, 1)",
  green_40: "#39DAAA",
  green: "hsla(155, 49%, 55%, 1)",
  green_60: "hsla(162, 86%, 33%, 1)",
  green_70: "hsla(155, 50%, 43%, 1)",
  green_80: "hsla(155, 97%, 27%, 1)",
  green_90: "hsla(155, 97%, 22%, 1)",
  green_100: "hsla(155, 100%, 11%, 1)",
  sky_0: "hsla(202, 100%, 98%, 1)",
  sky_10: "hsla(202, 100%, 94%, 1)",
  sky_20: "hsla(202, 100%, 85%, 1)",
  sky_30: "hsla(202, 100%, 73%, 1)",
  sky: "hsla(202, 100%, 60%, 1)",
  sky_70: "hsla(202, 83%, 49%, 1)",
  sky_80: "hsla(202, 100%, 37%, 1)",
  sky_90: "hsla(202, 100%, 28%, 1)",
  sky_100: "hsla(202, 100%, 15%, 1)",
  blue_0: "hsla(209, 100%, 98%, 1)",
  blue_10: "hsla(209, 100%, 95%, 1)",
  blue_20: "hsla(209, 100%, 87%, 1)",
  blue_30: "hsla(209, 85%, 76%, 1)",
  blue: "hsla(209, 61%, 64%, 1)",
  blue_50: "hsla(223, 90%, 56%, 1)",
  blue_70: "hsla(209, 55%, 54%, 1)",
  blue_80: "hsla(209, 65%, 43%, 1)",
  blue_90: "hsla(209, 87%, 32%, 1)",
  blue_100: "hsla(209, 100%, 19%, 1)",
  blue_500: "hsla(223, 98%, 61%, 1)",
  blue_600: "hsla(223, 78%, 51%, 1)",
  blue_700: "hsla(223, 88%, 41%, 1)",
  grey_0: "hsla(208, 100%, 99%, 1)",
  grey_4: "hsla(203, 50%, 97%, 1)",
  grey_6: "hsla(210, 50%, 96%, 1)",
  grey_8: "hsla(210, 42%, 95%, 1)",
  grey_10: "hsla(208, 40%, 94%, 1)",
  grey_12: "hsla(210, 33%, 93%, 1)",
  grey_20: "hsla(208, 24%, 87%, 1)",
  grey_26: "hsla(207, 19%, 79%, 1)",
  grey_30: "hsla(209, 16%, 74%, 1)",
  grey: "hsla(204, 11%, 61%, 1)",
  grey_70: "hsla(204, 9%, 50%, 1)",
  grey_80: "hsla(204, 9%, 42%, 1)",
  grey_84: "hsla(207, 11%, 37%, 1)",
  grey_90: "hsla(216, 14%, 29%, 1)",
  grey_96: "hsla(215, 24%, 22%, 1)",
  grey_98: "hsla(214, 29%, 19%, 1)",
  grey_100: "hsla(215, 35%, 16%, 1)",
  border: "hsla(210, 43%, 95%, 1)",
  cyan: "hsla(191, 75%, 47%, 1)",
  cyan_10: "hsla(191, 100%, 94%, 1)",
  cyan_100: "hsla(0, 0%, 0%, 0.08)",
  cyan_60: "hsla(191, 80%, 44%, 1)",
  cyan_80: "hsla(191, 100%, 20%, 1)",
  cyan_500: "hsla(190, 85%, 38%, 1)",
  teal_80: "hsla(175, 83%, 28%, 1)",
  red_80: "hsla(0, 53%, 52%, 1)",
  yellow_80: "hsla(36, 99%, 33%, 1)",
  yellow_30: "hsla(39, 98%, 78%, 1)",
  purple: "hsla(263, 66%, 53%, 1)",
  neutral_74: "hsla(210, 26%, 27%, 1)",
  neutral_82: "hsla(210, 27%, 19%, 1)",
  neutral_60: "hsla(208, 26%, 42%, 1)",
  neutral_12: "hsla(210, 21%, 89%, 1)",
  neutral_4: "hsla(210, 20%, 96%, 1)",
  neutral_8: "hsla(210, 20%, 92%, 1)",
  neutral_30: "hsla(208, 20%, 71%, 1)",
  neutral_100: "hsla(220, 14%, 96%, 1)",
  neutral_200: "hsla(220, 13%, 91%, 1)",
  neutral_400: "hsla(215, 9%, 65%, 1)",
  neutral_500: "hsla(218, 8%, 46%, 1)",
  neutral_600: "rgba(77, 87, 97, 1)",
  neutral_800: "hsla(212, 28%, 17%, 1)",
  neutral_900: "hsla(218, 39%, 11%, 1)",
  neutral_950: "hsla(218, 42%, 9%, 1)",
  neutral_1000: "hsla(223, 100%, 4%, 1)",
  grey_db: "#4D5761",
});
