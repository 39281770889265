export default Object.freeze({
  xxs: 330,
  xs: 360,
  sm: 545,
  avatarSm: 624,
  md: 767,
  lg: 1024,
  xl: 1280,
  xlg: 1380,
  xxl: 1440,
  xxxl: 1584,
  xxxxl: 1800,
});
