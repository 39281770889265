import React from "react";

import { StyledLandingBanner } from "./styles/StyledAbout";

export const LandingBanner = () => {
  return (
    <StyledLandingBanner>
      <div className="containerWrapper">
        <h1>At Hasura, we’re on a mission to make data access fast, secure & scalable.</h1>
        <p>
          We want to get to a world where data delivery is just another piece of infrastructure!
        </p>
      </div>
    </StyledLandingBanner>
  );
};
